import { useDataChange, useDataValue } from 'Simple/Data.js'
import {
  getPayorForDiscount,
  getUpdatedPayorConfiguration,
  changeInsurancesEstimatedReimbursementAmount,
  changePayorsShare,
  changeDiscountsAppliedToPayorsShare,
  changePayorsConfiguration,
} from 'Data/payment-plan.js'

export function useOnDelete(props) {
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let index = useDataValue({
    context: 'discount_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onDelete() {
    change(next => {
      let discount = next.discounts[index]
      next.discounts.splice(index, 1)

      if (discount.is_applied_to_payor) {
        let payor = getPayorForDiscount(discount, next)
        let { downpayment, installment_amount, payment_plan_length } =
          getUpdatedPayorConfiguration(payor, next)
        payor.downpayment = downpayment
        payor.installment_amount = installment_amount
        payor.payment_plan_length = payment_plan_length
      } else {
        if (discount.is_included_in_insurance_claim) {
          changeInsurancesEstimatedReimbursementAmount(next, treatment_plan)
        }

        changePayorsShare(next)
        changeDiscountsAppliedToPayorsShare(next)
        changePayorsConfiguration(next)
      }
    })
  }
}
