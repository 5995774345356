// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/CreateTreatmentPlan/EditTreatmentAndPaymentPlans/New/Steps/PaymentPlans/Content/PaymentPlan/Discounts/Content/Discount/Type/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_CreateTreatmentPlan_EditTreatmentAndPaymentPlans_New_Steps_PaymentPlans_Content_PaymentPlan_Discounts_Content_Discount_Type($location_id: uuid!) {
  vaxiom_discounts(
    where: { organization: { _id: { _eq: $location_id } } }
    order_by: { name: asc_nulls_last }
  ) {
    id
    text: name
    name
    default_amount
    default_percentage
    fixed
  }
}

`