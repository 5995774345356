import { useDataChange, useDataValue } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

export function useOnToggle(props) {
  let insurances = useDataValue({
    context: 'payment_plan',
    path: 'insurances',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onToggle(e) {
    if (insurances.length === 0) {
      change(next => {
        next.insurances.push({
          id: crypto.randomUUID(),
          is_placeholder: true,
          is_primary: true,
          estimated_reimbursement_amount: 0,
        })
      })
    } else {
      e.stopPropagation()

      setFlowTo(normalizePath(props.viewPath, '../RemoveInsurance/Content'))
    }
  }
}
