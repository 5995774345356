// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/CreateTreatmentPlan/EditTreatmentAndPaymentPlans/New/Steps/PaymentPlans/Content/PaymentPlan/Insurances/Insurance/Subscription/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_CreateTreatmentPlan_EditTreatmentAndPaymentPlans_New_Steps_PaymentPlans_Content_PaymentPlan_Insurances_Insurance_Subscription($patient_id: numeric!) {
  vaxiom_insured(where: { patient_id: { _eq: $patient_id } }) {
    id
    _id
    patient {
      id
      _id
      person {
        id
        _id
        birth_date
      }
    }
    tx_category_insured {
      id
      _id
      ortho_insured {
        id
        _id
        used_lifetime_coverage
        year_deductible_paid_last
      }
    }
    insurance_subscription {
      id
      _id
      person {
        id
        _id
        first_name
        last_name
      }
      enrollment_date
      insurance_plan {
        id
        _id
        number
        insurance_company {
          id
          carrier_name
          installment_interval
        }
        insurance_billing_center {
          id
          name
        }
        tx_category_coverages {
          id
          ortho_coverages {
            id
            deductible_amount
            reimbursement_percentage
            max_lifetime_coverage
            cob_type
            downpayment_type
            downpayment_amount
            downpayment_percentage
            downpayment_percentage_base
            waiting_period
            dependent_age_limit
            age_limit_cutoff
          }
        }
      }
    }
  }
}

`