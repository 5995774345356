import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let relationships = useDataValue({
    context: 'relationships',
    viewPath: props.viewPath,
  })
  let index = useDataValue({
    context: 'payor_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      let payor = next.payors[index]
      let previous_person_id = payor.person_id

      let person = relationships.find(item => item.id === value)
      payor.person_id = value
      payor.person = person

      // update all discounts previously linked to the previous person to the new one (no need to change the calculation)
      next.discounts.forEach(discount => {
        if (
          discount.is_applied_to_payor &&
          discount.payor_person_id === previous_person_id
        ) {
          discount.payor_person_id = value
        }
      })
    })
  }
}
