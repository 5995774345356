import { patientName, age, dateShortIn } from 'Data/format.js'

export function getTitle(person) {
  return [
    patientName(person),
    person.gender[0],
    person.birth_date ??
      `${age(person.birth_date)}, ${dateShortIn(person.birth_date)}`,
  ]
    .filter(Boolean)
    .join(', ')
}
