import {
  getPayorForDiscount,
  getUpdatedPayorConfiguration,
  changeInsurancesEstimatedReimbursementAmount,
  changePayorsShare,
  changePayorsConfiguration,
} from 'Data/payment-plan.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let index = useDataValue({
    context: 'discount_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      let discount = next.discounts[index]
      discount.amount = value
      discount.type = 'amount'

      if (discount.is_applied_to_payor) {
        let payor = getPayorForDiscount(discount, next)
        discount.percentage =
          payor.share.amount > 0
            ? Math.round((value / payor.share.amount) * 100)
            : 0

        // update payor's downpayment/installment
        let { downpayment, installment_amount, payment_plan_length } =
          getUpdatedPayorConfiguration(payor, next)
        payor.downpayment = downpayment
        payor.installment_amount = installment_amount
        payor.payment_plan_length = payment_plan_length
      } else {
        discount.percentage =
          next.treatment_fee > 0
            ? Math.round((value / next.treatment_fee) * 100)
            : 0

        if (discount.is_included_in_insurance_claim) {
          changeInsurancesEstimatedReimbursementAmount(next, treatment_plan)
        }

        changePayorsShare(next)
        changePayorsConfiguration(next)
      }
    })
  }
}
