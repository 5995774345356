import { useDataChange, useDataValue } from 'Simple/Data.js'
import { LOCK } from 'Data/payment-plan.js'

export function useOnLock(props) {
  let index = useDataValue({
    context: 'payor_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onLock() {
    change(next => {
      let payor = next.payors[index]
      payor.lock =
        payor.lock === LOCK.INSTALLMENT_AMOUNT
          ? LOCK.DOWNPAYMENT
          : LOCK.INSTALLMENT_AMOUNT
    })
  }
}
