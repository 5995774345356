import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(
    () =>
      data?.map(item => ({
        ...item,
        text: `${item.code} - ${item.title}`,
      })),
    [data]
  )
}
