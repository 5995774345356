import { useDataValue, useDataChange } from 'Simple/Data.js'
import {
  getUpdatedPayorConfiguration,
  getPayorForDiscount,
  changeInsurancesEstimatedReimbursementAmount,
  changePayorsShare,
  changeDiscountsAppliedToPayorsShare,
  changePayorsConfiguration,
} from 'Data/payment-plan.js'

export function useOnChange(props) {
  let types = useDataValue({
    context: 'discount_types',
    viewPath: props.viewPath,
  })
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let index = useDataValue({
    context: 'discount_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      let discount = next.discounts[index]

      if (value) {
        let type = types.find(item => item.id === value)

        discount.type_id = type.id
        discount.name = type.name

        let base
        if (discount.is_applied_to_payor) {
          let payor = getPayorForDiscount(discount, next)
          base = payor.share.amount
        } else {
          base = next.treatment_fee
        }

        if (type.fixed) {
          discount.type = 'amount'
          discount.amount = type.default_amount
          // dynamically calculate the equivalent percentage for the given amount
          discount.percentage =
            base > 0 ? Math.round((type.default_amount / base) * 100) : 0
        } else {
          discount.type = 'percentage'
          discount.percentage = type.default_percentage
          discount.amount = parseFloat(
            (base * (type.default_percentage / 100)).toFixed(2)
          )
        }

        // recalculate dependant values
        if (discount.is_applied_to_payor) {
          let payor = getPayorForDiscount(discount, next)
          let { downpayment, installment_amount, payment_plan_length } =
            getUpdatedPayorConfiguration(payor, next)
          payor.downpayment = downpayment
          payor.installment_amount = installment_amount
          payor.payment_plan_length = payment_plan_length
        } else {
          if (discount.is_included_in_insurance_claim) {
            changeInsurancesEstimatedReimbursementAmount(next, treatment_plan)
          }

          changePayorsShare(next)
          changeDiscountsAppliedToPayorsShare(next)
          changePayorsConfiguration(next)
        }
      } else {
        // allow unselecting discount
        discount.type_id = null
        discount.name = ''
      }
    })
  }
}
