import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'
import { getDefaultPrimaryPayor } from 'Data/payment-plan.js'

export default function useDataTransform(props, data) {
  let default_fee = useDataValue({
    context: 'treatment_plan',
    path: 'fee',
    viewPath: props.viewPath,
  })
  let insurance_code_id = useDataValue({
    context: 'treatment_plan',
    path: 'insurance_code_id',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    let default_payment_plan = {
      treatment_fee: default_fee,
      insurance_code_id,
      discounts: [],
      charges: [],
      // insurance section toggled on by default
      insurances: [
        {
          id: crypto.randomUUID(),
          is_placeholder: true,
          is_primary: true,
          estimated_reimbursement_amount: 0,
          is_overridden: false,
        },
      ],
      payors: [],
    }

    return {
      ...default_payment_plan,
      payors: [getDefaultPrimaryPayor(default_payment_plan)],
    }
  }, [default_fee, insurance_code_id])
}
